/*Question*/
.question {
  position: relative;
  margin: 0;
  padding: 10px 10px 10px 0px;
  display: block;
  width: 100%;
  cursor: pointer;
  color: var(--PRIMARYCOLOR);
  text-align: left;
}
/*Answer*/
.answer {
  padding: 0px;
  margin: 5px 0;
  width: 100% !important;
  height: 0;
  overflow: hidden;
  position: relative;
  opacity: 0;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  color: #000;
}
.answer > a {
  font-weight: 700;
  text-decoration: underline;
  color: var(--PRIMARYCOLOR);
}
.answer > a:active {
  opacity: 0.5;
}
.answer > ul {
  padding-inline: inherit;
  list-style-type: "- ";
}
.answer > ul > li:not(:last-child) {
  margin-bottom: 5px;
}
.questions:checked ~ .answer {
  height: auto;
  opacity: 1;
  padding: 15px 0;
}
.questions {
  display: none;
  z-index: 1000;
}
/* Stripe */
.StripeElement {
  border-radius: 4px !important;
}
/* Google Pay Button */
.gpay-button {
  border-radius: 4px !important;
  font-size: 1rem !important;
  width: 100% !important;
  height: 2.75rem !important;
  min-height: unset !important;
  border: none !important;
}
/* Swiper Navigation */
.slider {
  width: 100%;
  overflow-y: hidden;
}
.thin-scroll::-webkit-scrollbar {
  width: 1px;
}
