@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --PRIMARYCOLOR: #0a372b;
  --PRIMARYTEXTCOLOR: #58736b;
  --PRIMARYBUTTONGRADIENT: linear-gradient(90deg, #58736b 10.53%, #4d8977 89.61%);
  --PRIMARYBACKGROUND: 0 0% 95%;
  --PRIMARYBUTTONTEXT: #fffbe9;
  --BLACK: #1c1c1e;
  --WHITE: #fff;
  --GRAYTEXT: #8e8ea9;
  --DIVIDERCOLOR: #d9d9d9;
  --GRAYBORDER: #eaeaef; /* DO NOT USE */
  --BILLINGBACKGROUND: #fff;
  --SUCCESS: #34c759;
  --PENDING: #ff9500;
  --FAILED: #ff3b30;
  --STARFILL: #ffce34;
  --STARSTROKE: #fdc000;
  --DISCOUNTBLUE: #007aff;
  --HeaderHeight: 55px;
  --radius: 0.5rem;
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  background-color: hsl(0, 0%, 95%);
  min-height: 100vh;
  min-height: -moz-available;
  min-height: 100svh;
  max-height: 100vh;
  max-height: 100lvh;
  overflow: hidden;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}
#root,
#nina {
  background-color: hsl(0, 0%, 95%);
  overflow: hidden;
  position: fixed;
  inset: 0;
  inset: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  overflow: hidden;
}
.page-height {
  height: calc(95% - var(--HeaderHeight));
}
.full-bottom-sheet {
  height: calc(100% - var(--HeaderHeight));
}
.textGradient {
  background: var(--PRIMARYBUTTONGRADIENT);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.lottie-container > .lf-player-container {
  height: 100%;
  width: 100%;
}

/* Drink Card Trapazoid shape */
.glass {
  transform: perspective(100px) rotateX(-1deg);
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -webkit-touch-callout: none;
}
input:focus-within {
  outline: none;
}
/* Webkit paste style */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #0a372b !important;
}
